import React from "react"

import Layout from "../layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout homepage={true}>
    <SEO title="Ant Money Advisors" />
  </Layout>
)

export default IndexPage
